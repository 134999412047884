import React, {ChangeEvent, useRef, useState} from 'react';
import {composeUrl} from "../../utils/api";
import {useAuth} from "react-oidc-context";
import {getClaimValue, hasRoleClaim} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {useForm} from "react-hook-form";
import StateButton from "../Shared/StateButton/StateButton";

import uploadIcon from "../../images/upload.svg";
import refreshIcon from "../../images/refresh.svg";
import checkCircle from "../../images/check-circle.svg";
import xCircle from "../../images/x-circle.svg";
import alertCircle from "../../images/alert-circle.svg";
import {useNavigate} from "react-router-dom";
import useInterval from "../Shared/Hooks/useInterval";
import WellKnownRoles from "../../models/wellKnownRoles";

export enum MemberFileStatusId {
    New = 1,
    Processing,
    Review,
    Confirming,
    Confirmed,
    Abandoned,
    Error
}

interface MemberFile {
    id: number,
    filename: string,
    statusId: MemberFileStatusId,
    statusName: string,
    uploadedOn: Date,
    reviewedOn: Date,
    new: number,
    updated: number,
    removed: number,
    unchanged: number
}

interface FormData {
    fileName: string
}

function Members() {
    const auth = useAuth();
    const navigate = useNavigate();
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = useState<File>();
    const [isEmptyFile, setIsEmptyFile] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<MemberFile[]>([]);
    const [tablePage, setTablePage] = useState<number>(1);
    const [maxTablePage, setMaxTablePage] = useState<number>(1);
    const [intervalDelay] = useState(5000);
    const [shouldAutoRefresh, setShouldAutoRefresh] = useState(false);
    const [error, setError] = useState("");

    useInterval(() => refresh(), shouldAutoRefresh ? intervalDelay : null);

    const methods = useForm<FormData>();

    const paginationLimit = 10;
    const processingStatuses: MemberFileStatusId[] = [MemberFileStatusId.New, MemberFileStatusId.Processing];

    React.useEffect(() => {
        if (!hasRoleClaim(auth.user, WellKnownRoles.OrganisationMemberManager)) {
            navigate('/access-denied');
        }

        refresh()
    }, []);

    function refresh() {
        setError("");
        let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
        fetch(composeUrl(`organisations/${orgUuid}/memberFiles`),
            {
                headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ]],
                method: 'GET'
            })
            .then(response => response.json() as Promise<MemberFile[]>)
            .then(data => {
                let sortedUploads = data.sort((a, b) => { return (new Date(a.uploadedOn).valueOf() - new Date(b.uploadedOn).valueOf()) * -1 });
                if (sortedUploads.length > 0) {
                    switch (sortedUploads[0].statusId) {
                        case MemberFileStatusId.New:
                        case MemberFileStatusId.Processing:
                        case MemberFileStatusId.Review:
                        case MemberFileStatusId.Confirming:
                            setUploadDisabled(true);
                            break;
                        case MemberFileStatusId.Error:
                        case MemberFileStatusId.Abandoned:
                        case MemberFileStatusId.Confirmed:
                            setUploadDisabled(false);
                            break;
                    }

                    setTablePagination(sortedUploads);
                    setUploadedFiles(sortedUploads);
                } else {
                    setUploadDisabled(false);
                }
                setShouldAutoRefresh(data.filter(f =>
                    f.statusId === MemberFileStatusId.New ||
                    f.statusId === MemberFileStatusId.Processing ||
                    f.statusId === MemberFileStatusId.Confirming).length > 0);
            });
    }

    function setTablePagination(memberFiles: MemberFile[]) {
        let maxPage = Math.ceil(memberFiles.length / paginationLimit);
        setMaxTablePage(maxPage);
    }

    function handleClick() {
        inputFile.current?.click();
    }

    function fileSelected(event: ChangeEvent<HTMLInputElement>) {
        let file = event.target.files?.item(0);
        if (file) {
            setFile(file);
        }
    }

    const upload = async() => {
        setError("");
        setIsEmptyFile(false);
        if (file) {
            if (file.size === 0) {
                setIsEmptyFile(true);
            } else {
                setUploading(true);
                setUploadDisabled(true);
                let formData = new FormData();
                formData.append('file', file, file?.name);

                try {
                    let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
                    const response = await fetch(composeUrl(`organisations/${orgUuid}/memberFiles`),
                        {
                            headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
                            method: 'POST',
                            body: formData
                        });

                    if (response.ok) {
                        refresh();
                    } else {
                        const responseBody = await response.json();
                        if (responseBody.hasOwnProperty('code') && responseBody.hasOwnProperty('message')) {
                            setError(responseBody.message);
                        } else {
                            setError("Sorry an error has occurred");
                        }
                    }
                    enableUpload();
                }
                catch (e) {
                    setError("Sorry an error has occurred");
                    enableUpload();
                }
            }
        }
    };

    const enableUpload = () => {
        setUploading(false);
        setUploadDisabled(false);
        if(inputFile && inputFile.current) {
            // @ts-ignore
            inputFile.current.value = null;
            setFile(undefined);
        }
    };

    function getStatusElement(memberFile: MemberFile): JSX.Element {
        let icon = undefined;
        let iconAlt = "";
        let additionalClasses = "";
        switch (memberFile.statusId) {
            case MemberFileStatusId.New:
            case MemberFileStatusId.Processing:
            case MemberFileStatusId.Confirming:
                icon = refreshIcon;
                iconAlt = "Processing";
                additionalClasses = 'animate-spin';
                break;
            case MemberFileStatusId.Confirmed:
                icon = checkCircle;
                iconAlt = "Confirmed";
                break;
            case MemberFileStatusId.Error:
                icon = alertCircle;
                iconAlt = "Error";
                break;
            case MemberFileStatusId.Abandoned:
                icon = xCircle;
                iconAlt = "Abandoned";
                break;
            default:
                break;
        }

        return <div className="leading-8 flex flex-row items-center">
            {
                icon &&
                <img src={icon} alt={iconAlt} className={"mr-sm h-lg " + additionalClasses}/>
            }
            {
                processingStatuses.includes(memberFile.statusId) ?
                    MemberFileStatusId[MemberFileStatusId.Processing] :
                    memberFile.statusId === MemberFileStatusId.Review ?
                        <button
                            className="flex max-h-xl max-w-[8rem] items-center justify-center font-sans-co"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`review/${memberFile.id}`);
                            }}>
                            Review
                        </button> :
                        MemberFileStatusId[memberFile.statusId]
            }
        </div>
    }

    const navigateToDetails = (memberFileId: number) => {
        navigate(`details/${memberFileId}`);
    }

    return (
        <form onSubmit={methods.handleSubmit(upload)}>
            <div className="grid grid-cols-4 grid-rows-auto">
                <div className="col-span-3 items-start mb-xl">
                    <h1 className="mb-xl">Members</h1>
                    <div className="mb-md">
                        <div className="flex flex-row items-center">
                            <input type="file" onChange={fileSelected} hidden={true} accept="text/csv" ref={inputFile}/>
                            <input
                                { ...methods.register("fileName", { required: true }) }
                                className="border-2 border-primary-text w-full p-xs mb-xs"
                                type="text" value={file?.name ?? ''}
                                placeholder="Select CSV file...."
                                onClick={handleClick}/>
                            <img src={uploadIcon} alt="Upload_Icon" className="ml-sm h-lg" />
                        </div>
                        { methods.formState.errors.fileName?.type == 'required' && <p className="text-p2 text-error-red">Please select a CSV file to upload.</p> }
                        { isEmptyFile && <p className="text-p2 text-error-red">File is empty.</p> }
                        { error && <p className="text-p2 text-error-red">{error}.</p> }
                    </div>
                    <div className="flex flex-row items-center gap-lg">
                        <StateButton submit={true} disabled={uploadDisabled} loading={uploading}>Upload</StateButton>
                        <a href="/files/Member_File_Template.csv" download className="cursor-pointer">Download template</a>
                    </div>
                </div>
                <table className="col-span-4 mt-xl">
                    <thead className="bg-super-light-grey">
                    <tr className="h-2xl text-left text-primary-text">
                        <th className="px-md py-sm border-b-2 border-primary-text">Date</th>
                        <th className="px-md py-sm border-b-2 border-primary-text rounded-l-md">Name</th>
                        <th className="px-md py-sm border-b-2 border-primary-text">Status</th>
                        <th className="px-md py-sm border-b-2 border-primary-text">New</th>
                        <th className="px-md py-sm border-b-2 border-primary-text">Removed</th>
                        <th className="px-md py-sm border-b-2 border-primary-text">Updated</th>
                        <th className="px-md py-sm border-b-2 border-primary-text rounded-r-md">Unchanged</th>
                    </tr>
                    </thead>
                    <tbody>
                    { uploadedFiles.length > 0 &&
                        uploadedFiles.slice((tablePage -1) * paginationLimit, tablePage * paginationLimit).map((memberFile, index) =>
                            <tr key={index} className="text-left bg-pure-white even:bg-super-light-grey h-2xl">
                                <td className="px-md py-sm border-y-2 border-background-blu">
                                        <span className="text-mid-grey mr-sm">
                                            {new Date(memberFile.uploadedOn).toLocaleDateString()}
                                        </span>
                                </td>
                                <td className="px-md py-sm border-y-2 border-background-blu rounded-l-md">
                                    <a className="hover:cursor-pointer"
                                       onClick={() => navigateToDetails(memberFile.id)}>
                                        {memberFile.filename}
                                    </a>
                                </td>
                                <td className="px-md py-sm border-y-2 border-background-blu">{getStatusElement(memberFile)}</td>
                                <td className="px-md py-sm w-4xl border-y-2 border-background-blu">{memberFile.statusId === MemberFileStatusId.Confirmed && memberFile.new}</td>
                                <td className="px-md py-sm w-4xl border-y-2 border-background-blu">{memberFile.statusId === MemberFileStatusId.Confirmed && memberFile.removed}</td>
                                <td className="px-md py-sm w-4xl border-y-2 border-background-blu">{memberFile.statusId === MemberFileStatusId.Confirmed && memberFile.updated}</td>
                                <td className="px-md py-sm w-4xl border-y-2 border-background-blu rounded-r-md">{memberFile.statusId === MemberFileStatusId.Confirmed && memberFile.unchanged}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="flex flex-row mt-lg ml-md">
                    {
                        tablePage > 1 ?
                            <a className="hover:cursor-pointer text-mid-grey mr-md" onClick={() => setTablePage(tablePage - 1)}>{"< Previous"}</a> :
                            <p className="text-light-grey mr-md">{"< Previous"}</p>
                    }
                    {
                        tablePage < maxTablePage ?
                            <a className="hover:cursor-pointer text-mid-grey" onClick={() => setTablePage(tablePage + 1)}>{"Next >"}</a> :
                            <p className="text-light-grey">{"Next >"}</p>
                    }
                </div>
            </div>
        </form>
    );
}

export default Members;